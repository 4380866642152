import $ from 'jquery';
window.$ = $;
window.jQuery = $;


import 'bootstrap';
import '@ungap/custom-elements';

import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

// Enable tooltips
import Tooltip from "bootstrap/js/dist/tooltip";

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
tooltipTriggerList.map((elem) => {
  return new Tooltip(elem);
});

import SiteHeader from "./js/components/SiteHeader";
import StationsMap from "./js/components/StationsMap";
import TextSlider from "./js/components/TextSlider";
import Avis from "./js/components/Avis";
import Tarifs from "./js/components/Tarifs";
import Vote from "./js/components/Vote";
import CardVideoFullwidth from "./js/components/CardVideoFullwidth";
import IntlTelInput from "./js/components/IntlTelInput";
import Inscription from "./js/components/Inscription";
import InscriptionSummary from "./js/components/InscriptionSummary";



// Define css variable used for css fullwidth features
window.addEventListener("DOMContentLoaded", (e) => {

    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);

    // Custom elements

    customElements.define("site-header", SiteHeader);
    customElements.define("stations-map", StationsMap);
    customElements.define("text-slider", TextSlider);
    customElements.define("avis-mount", Avis);
    customElements.define("tarifs-mount", Tarifs);
    customElements.define("vote-mount", Vote);
    customElements.define("card-video-fullwidth", CardVideoFullwidth, { extends: 'div' });
    customElements.define("intl-tel-input", IntlTelInput, { extends: 'div' });
    customElements.define("inscription-mount", Inscription, { extends: 'div' });
    customElements.define("inscription-summary", InscriptionSummary, { extends: 'div' });
})

import './scss/main.scss';
